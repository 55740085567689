import React from 'react'
import Navigation from '../../components/Navigation'
import { Helmet } from 'react-helmet-async'
import { NavLink } from 'react-router-dom'

const Home: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Cultuurcentrum Opus '78</title>
      </Helmet>

      <div className="site-content home mt-2">
        <div className="hero">
          <h1>Jouw plek voor muziek, cultuur en bijzondere momenten</h1>
          <p>
            Ons Cultuurcentrum biedt een inspirerende en vooral ook praktische ruimte voor (semi)professionele
            cultuurverenigingen. Daarnaast zijn we de perfecte locatie voor al uw onvergetelijke evenementen. Neem
            contact met ons op en ontdek hoe wij jouw bijeenkomst speciaal kunnen maken!
          </p>

          <div className="group mt-2">
            <NavLink to={'/contact'} className={'btn'}>
              Neem contact op
            </NavLink>
            <NavLink to={'/zaalreserveringen'} className={'btn btn-color-transparent btn-variant-outline'}>
              Bekijk onze agenda
            </NavLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
