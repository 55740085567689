import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Home from '@/pages/home'
import RoomReservations from '@/pages/room-reservations'
import NotFound from '@/pages/not-found/NotFound'
import UnderConstruction from '@/pages/under-construction'
import Contact from '@/pages/contact'

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/zaalreserveringen" element={<RoomReservations />} />
      <Route path="/onze-locatie" element={<UnderConstruction />} />
      <Route path="/onze-huurders" element={<UnderConstruction />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AppRoutes
