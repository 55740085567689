import React from 'react'

import './RoomReservation.scss'

const RoomReservations: React.FC = () => {
  return (
    <>
      <div className="room-reservation mt-2">
        <iframe
          src="https://www.supersaas.nl/schedule/Opus78/zalen"
          title="W3Schools Free Online Web Tutorials"
        ></iframe>
      </div>
    </>
  )
}

export default RoomReservations
