import React from 'react'
import { Link } from 'react-router-dom'

const NotFound: React.FC = () => {
  return (
    <>
      <div className="not-found" />
      <div className="site-content home mt-2">
        <h1>404 - Pagina Niet Gevonden</h1>
        <p>Oeps! Het lijkt erop dat je een verkeerde afslag hebt genomen.</p>
        <p>Maak je geen zorgen, het overkomt de besten van ons.</p>
        <p>
          Laten we je weer op weg helpen. <Link to="/">Ga naar Home</Link>
        </p>
      </div>
    </>
  )
}

export default NotFound
