import React from 'react'
import './Grid.scss'

interface GridProps {
  children: React.ReactNode
  columns?: 1 | 2 | 3 | 4 | 5 | 6
  gap?: 1 | 2 | 3
  extraClasses?: string
}

const Grid: React.FC<GridProps> = ({ children, columns = 1, gap = 1, extraClasses }) => {
  const gridStyle = `grid grid-col-${columns} grid-gap-${gap} ${extraClasses}`
  return <div className={gridStyle}>{children}</div>
}

export default Grid
