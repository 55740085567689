import React from 'react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'

import AppRoutes from './routes/AppRoutes'
import Footer from './components/Footer'
import Navigation from './components/Navigation'
import './index.css'

createRoot(document.body).render(
  <StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <Navigation />
        <AppRoutes />
        <Footer />
      </BrowserRouter>
    </HelmetProvider>
  </StrictMode>
)
