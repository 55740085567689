import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import './Navigation.scss'

const Navigation: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }
  const location = useLocation()

  return (
    <div className="site-header">
      <div className="logo">
        <NavLink to="/" onClick={toggleMenu}>
          <img src={'./images/logo.png'} alt="Logo" />
        </NavLink>
      </div>

      <nav className={`site-nav ml-3 ${isOpen ? 'open' : ''}`}>
        <ul>
          <li className={location.pathname === '/' ? 'active' : ''} onClick={toggleMenu}>
            <NavLink to="/">Home</NavLink>
          </li>
          <li className={location.pathname === '/onze-locatie' ? 'active' : ''} onClick={toggleMenu}>
            <NavLink to="/onze-locatie">Onze locatie</NavLink>
          </li>
          <li className={location.pathname === '/onze-huurders' ? 'active' : ''} onClick={toggleMenu}>
            <NavLink to="/onze-huurders">Onze huurders</NavLink>
          </li>
          <li className={location.pathname === '/zaalreserveringen' ? 'active' : ''} onClick={toggleMenu}>
            <NavLink to="/zaalreserveringen">Zaalreserveringen</NavLink>
          </li>
          <li className={location.pathname === '/contact' ? 'active' : ''} onClick={toggleMenu}>
            <NavLink to="/contact">Contact</NavLink>
          </li>
        </ul>
      </nav>
      <div className="hamburger" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}

export default Navigation
