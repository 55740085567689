import React from 'react'

interface GridItemProps {
  children: React.ReactNode
  colspan?: 1 | 2 | 3 | 4 | 5 | 6
  extraClasses?: string
}

const GridItem: React.FC<GridItemProps> = ({ children, colspan = 1, extraClasses }) => {
  const style = `grid-item grid-item-col-${colspan} ${extraClasses}`

  return <div className={style}>{children}</div>
}

export default GridItem
