import React from 'react'
import { Helmet } from 'react-helmet-async'

import './Contact.scss'
import { Grid, GridItem } from '@/components/Grid'

const Contact: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Cultuurcentrum Opus &apos;78 - Contact</title>
      </Helmet>

      <div className="site-content contact mt-2 pt-1">
        <div className="content-center mb-4">
          <h1>Contact</h1>
          <p>Neem gerust contact met ons op via de onderstaande gegevens.</p>
        </div>

        <Grid columns={2}>
          <GridItem>
            <h1>Contact</h1>

            <strong>Voorzitter</strong>
            <p>
              S.G. Hut
              <br />
              E-mail: <a href="mailto:voorzitter@opus78.nl">voorzitter@opus78.nl</a>
            </p>

            <hr style={{ margin: '1rem 0', border: 'none', height: '1px', backgroundColor: 'white' }} />

            <strong>Beheer</strong>
            <p>
              M. van Houten
              <br />
              E-mail: <a href="mailto:beheer@opus78.nl">beheer@opus78.nl</a>
            </p>

            <hr style={{ margin: '1rem 0', border: 'none', height: '1px', backgroundColor: 'white' }} />

            <strong>Verhuur</strong>
            <p>
              F. Wapstra
              <br />
              E-mail: <a href="mailto:verhuur@opus78.nl">verhuur@opus78.nl</a>
              <br />
              Telefoonnummer: <a href="tel:+31655131198">+31 6 55131198</a>
            </p>
          </GridItem>

          <GridItem extraClasses="bg-white tc-b p">
            <h2>Adres</h2>
            <p>
              Cultuurcentrum Opus &apos;78
              <br />
              Van Loonstraat 122
              <br />
              8932 AV Leeuwarden
            </p>
            <p>
              <strong>Postadres</strong>: <br />
              Postbus 8045 <br />
              8903 KA <br />
              Leeuwarden <br />
              <br />
              KVK: 41000358
            </p>
          </GridItem>
        </Grid>
      </div>
    </>
  )
}

export default Contact
