import React from 'react'
import './Footer.scss'
import ExternalLink from '../ExternalLink'

const Footer: React.FC = () => {
  return (
    <footer className="mt-6">
      <div className="footer-content">
        <div className="footer-top">
          <div className="footer-left-content">
            <div className="footer-logo"></div>
          </div>

          <div className="footer-right-content">
            <ul>
              <li>
                <ExternalLink href="./documents/Cameraprotocol Opus - versie 2.0 - september 2024.pdf">
                  Cameraprotocol
                </ExternalLink>
              </li>

              <li>
                <ExternalLink href="./documents/Privacyverklaring tbv Cameraprotocol Opus - versie 1.0 - september 2024.pdf">
                  Privacyverklaring tbv Cameraprotocol
                </ExternalLink>
              </li>

              <li>
                <ExternalLink href="./documents/Privacyverklaring Opus - versie 1.0 - september 2024.pdf">
                  Privacyverklaring
                </ExternalLink>
              </li>
            </ul>
          </div>
        </div>

        <div className="divider"></div>

        <div className="footer-bottom">
          <div className="copy-text">&copy; {new Date().getFullYear()} Cultuurcentrum Opus '78</div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
