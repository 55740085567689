import React, { ReactNode } from 'react'

interface ExternalLinkProps {
  children: ReactNode
  href: string
}

const ExternalLink: React.FC<ExternalLinkProps> = ({ children, href }) => {
  const isDownloadable = href.endsWith('.pdf') || href.endsWith('.docx') || href.endsWith('.xlsx')

  return (
    <a href={href} target="_blank" rel="noopener noreferrer" download={isDownloadable ? true : undefined}>
      {children}
    </a>
  )
}

export default ExternalLink
