import React from 'react'
import { Helmet } from 'react-helmet-async'
import { NavLink } from 'react-router-dom'

const UnderConstruction: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Cultuurcentrum Opus '78 - under construction</title>
      </Helmet>

      <div className="site-content home mt-2">
        <div className="hero">
          <h1>We zijn bijna klaar!</h1>
          <p>
            Onze website is momenteel in aanbouw. We werken zo hard dat zelfs onze toetsenborden zweten! Bedankt voor uw
            geduld!
          </p>

          <div className="group mt-2"></div>
          <div className="group mt-2">
            <NavLink to={'/contact'} className={'btn'}>
              Neem contact op
            </NavLink>
            <NavLink to={'/zaalreserveringen'} className={'btn btn-color-transparent btn-variant-outline'}>
              Bekijk onze agenda
            </NavLink>
          </div>

          <img
            src="https://media.giphy.com/media/8PpFJcG4y8HqsxQumz/giphy.gif"
            alt="Under Construction"
            className="under-construction-gif mt-4"
          />
        </div>
      </div>
    </>
  )
}

export default UnderConstruction
